import { FC, ReactNode } from 'react'
import { useHistory } from 'react-router-dom'

import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import s from './index.module.scss'

type Props = {
  title: string
  backRoute?: string
  children?: ReactNode
}

const Header: FC<Props> = ({ title, backRoute, children }) => {
  const history = useHistory()

  return (
    <div className={s.Wrapper}>
      <div className={s.LeftSide}>
        {backRoute && (
          <>
            <button className={s.BackBtn} onClick={() => history.push(backRoute)}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <div className={s.Divider} />
          </>
        )}

        <h3 className={s.Title}>{title}</h3>
      </div>

      <div>{children && children}</div>
    </div>
  )
}

export default Header
